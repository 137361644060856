.body {
    justify-content: center;
    margin: 0px;
}

.top-home {
    text-align: center;
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
}

#heading-home {
    font-size: 60px;
    max-width: 1200px;
    font-weight: 1000;
    margin-bottom: 50px;
    width: 70%;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    #heading-home {
        width: 95%;
        font-size: 40px;
        font-weight: 1000;
        margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    .top-home {
        text-align: center;
        margin-top: 60px;
    }
  }

.btns-home {
    margin-top: 30px;
}

#blue-heading {
    color: #0A77DC;
    text-align: middle;
}

#subheading-home {
    font-size: 24px;
    font-weight: 500;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: middle;
    color: #6E7984;
}

@media only screen and (max-width: 600px) {
    #subheading-home {
        font-size: 20px;
        font-weight: 500;
        color: #6E7984;
    }
  }

#btn-2 {
    padding: 20px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 20px;
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    color: #bbc2c8;
    border-radius: 10px;
    border: 1px solid #dadfed;
}

@media only screen and (max-width: 600px) {
    #btn-2 {
        width: 95%;
        margin-right: 0px;
        margin-bottom: 10px;
        padding: 20px;
        padding-right: 30px;
        padding-left: 30px;
        font-size: 20px;
        background-color: rgb(255, 255, 255);
        font-weight: 600;
        color: #000000;
        border-radius: 10px;
        border: 1px solid #dadfed;
        cursor: pointer;
    }
  }

#btn-1 {
    padding: 20px;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 20px;
    background-color: #0A77DC;
    font-weight: 600;
    color: white;
    border-radius: 10px;
    border: 1px solid #E4ECFF;
    cursor: pointer;
}
@media only screen and (max-width: 600px) {
    #btn-1 {
        width: 95%;
        padding: 20px;
        padding-right: 30px;
        padding-left: 30px;
        font-size: 20px;
        background-color: #0A77DC;
        font-weight: 600;
        color: white;
        border-radius: 10px;
        border: 1px solid #E4ECFF;
        cursor: pointer;
    }
  }

.bottom-home {
    margin-top: 100px;
}

.bottom-home h2 {
    font-weight: 500;
    color: #6E7984;
    font-size: 20px;
}

.offersdemo {
    width: 95%;
    max-width: 1000px; /* Limit the maximum width of the image */
    height: auto; /* Maintain the aspect ratio */
}

  .offer-rectangle3 {
    width: 95%;
    max-width: 1200px;
    height: 55px;
    background-color: #ffffff;
    border:1px solid #DADFED;
    border-radius: 20px;
    padding: 20px;
    text-align: left;
    margin: auto;
    margin-bottom: 20px;
    align-items: center;
    justify-content: unset;
    position: relative;
  }

  @media only screen and (max-width: 600px) {
    .offer-rectangle3 {
      width: 80%;
      background-color: #ffffff;
      border:1px solid #DADFED;
      border-radius: 20px;
      padding: 20px;
      margin: auto;
      align-items: center;
      margin-bottom: 20px;
      justify-content: unset;
      position: relative;
    }
  }


  .price-container3 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    float: right;
    position: absolute;
    top: 15px;
    right: 20px;
  }

  #offer-title3 {
    margin-bottom: 0px;
  }
  
  #offer-p3 {
    font-size: 16px;
    margin-top: 0;
  }

  @media only screen and (max-width: 600px) {
    #offer-p3 {
      width: 70%;
      font-size: 16px;
      margin-top: 0;
    }
  }

  .price3 {
    font-size: 24px;
  }

  .offers-text3 {
    margin: 5px;
    margin-bottom: -8px;
    font-size: 18px;
  }
  
  .offer-rectangle3 h3 {
    margin: 0;
  }

  .offer-rectangle3 p {
    color: #6E7984;
  }

  #offer-quant {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  
  .offer-rectangle-special {
    width: 95%;
    max-width: 1200px;
    height: 55px;
    background-color: #0A77DC;
    border:1px solid #DADFED;
    border-radius: 20px;
    padding: 20px;
    text-align: left;
    margin: auto;
    margin-bottom: 20px;
    align-items: center;
    justify-content: unset;
    position: relative;
  }

  .offer-rectangle-special p {
    color: white;
  }

  #offer-quant-special {
    margin-bottom: 20px;
    margin-right: 20px;
    color: white;
  }
  
  .offers-text-special {
    margin: 5px;
    margin-bottom: -8px;
    font-size: 18px;
  }


  #offer-title-special {
    margin-bottom: 0px;
    color: white;
  }

  .price-container-special {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    float: right;
    position: absolute;
    top: 15px;
    right: 20px;
  }

  #offer-p-special {
    font-size: 16px;
    margin-top: 0;
  }

  .price-special {
    font-size: 24px;
    color: white;
  }

  .offer-rectangle-special h3,
.offer-rectangle-special p {
  display: flex;
  align-items: center;
  margin: 0;
}

.special-cta {
    margin-top: 7px;
    padding: 15px;
    border: 1px solid #dadfed;
    background-color: white;
    font-weight: 700;
    font-size: 16px;
    border-radius: 15px;
}

.special-cta:hover {
    background-color: rgb(240, 243, 250);
    transition: .2s ease-in-out;
    cursor: pointer;
}

#examples {
    margin-bottom: 40px;
}