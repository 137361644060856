.footer {
    background-color: #ffffff;
    border-top: 1px solid #DADFED;
    padding: 20px;
    color: #555;
    position: fixed;
    bottom: 0;
    width: 80%;
  }
  
  .footer-content {
    background-color: #ffffff;
    border-top: 1px solid #DADFED;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  
  .company-info h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .company-info p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .footer-links {
    margin-left: 30px;
  }
  
  .footer-links h4 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-links li {
    margin-bottom: 5px;
  }
  
  .footer-links a {
    color: #555;
    text-decoration: none;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-bottom p {
    font-size: 0.8rem;
    color: #777;
  }
  
  .apply {
    text-align: center;
    margin-top: 30px;
    padding: 30px;
  }

  #applybtn {
    width: 300px;
    border: 1px solid #DADFED;
    background-color: #000000;
    color: white;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  #sub-foot {
    font-size: 18px;
    font-weight: 500;
    color: #6E7984;
  }