

.sell-page {
  margin: 3%;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #FAFFFB;
  /* ...other styles... */
}

@media only screen and (max-width: 600px) {
    .sell-page {
        margin: 5%;
      }
  }

.sell-top {
  font-size: 16px;
  font-weight: 1000;
  margin-top: 60px;
}

.sell-header {
  font-size: 25px;
  max-width: 1000px;
  margin: auto;
  font-weight: 1000;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .sell-header {
    font-size: 20px;
    font-weight: 1000;
    margin-bottom: 30px;
  }
}

.sub-header {
  font-weight: 500;
  color: #6E7984;
}

.colored-text {
  color: #0A77DC;
}

.apply-button {
  margin-bottom: 30px;
  padding: 20px 25px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: black;
  border-radius: 10px;
  border: 1px solid #E4ECFF;
  cursor: pointer;
}

.card-container {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.card {
  padding: 25px;
  color: white;
  border: 1px solid #DADFED;
  border-radius: 20px;
  background-color: white;
  
}

@media only screen and (max-width: 600px) {
    .card {
        width: 90%;
          padding: 25px;
          color: white;
          border: 1px solid #DADFED;
          border-radius: 20px;
          background-color: white;
        }
  }

.card-heading {
  font-size: 18px;
  font-weight: 500;
  color: #6E7984;
}

.card-title {
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.card-button {
  padding: 15px;
  width: 100%;
  margin-right: 15px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 18px;
  background-color: #0A77DC;
  font-weight: 600;
  color: white;
  border-radius: 10px;
  border: 1px solid #DADFED;
  cursor: pointer;
}

.card-message {
  padding: 15px;
  width: 100%;
  margin-right: 15px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 18px;
  background-color: rgb(255, 255, 255);
  font-weight: 500;
  color: #6E7984;
  border-radius: 10px;
  border: 1px solid #DADFED;
}

.step4-title {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.step4-sub {
  font-size: 18px;
  font-weight: 400;
  color: #6E7984;
}

.linkcard {
  padding: 25px;
  border: 1px solid #DADFED;
  border-radius: 20px;
  text-decoration: none;
  background-color: white;
}

.linkcard:hover {
  padding: 25px;
  border: 1px solid #DADFED;
  border-radius: 20px;
  text-decoration: none;
  background-color: #FCFDFF;
}

.linkcard-title {
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
}

.linkcard-sub {
  margin: 0px;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #6E7984;
  text-decoration: none;
}

.linkcard-container {
  margin-top: 30px;
  margin-bottom: 100px;
  text-decoration: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  text-align: left;
  text-decoration: none;
}

.linkcard-container a {
  text-decoration: none;
}

.down-icon {
    float: right;
    margin-right: 5px;
    color: black;
}

#announce {
  text-align: center;
  background-color: rgb(251, 238, 243);
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  color: #dd126d;
  font-weight: 500;
}

#announce a {
  text-decoration: underline;
  color: black;
}

#new-tag {
  background-color: #dd126d;
  border-radius: 10px;
  font-size: 14px;
  padding: 2px 6px;
  color: white;
}