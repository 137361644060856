

.calcpage {
    margin: auto;
    margin-top: 4%;
    justify-content: center;
    align-items: center;
    
    font-family: 'Poppins', sans-serif;
    /* ...other styles... */
  }
  
  .calctop {
    margin: auto;
    justify-content: center;
  }
  
  .price-range {
    width: 100%;
    height: 100%;
  }
  
  .range-indicator {
    height: 100%;
    color: white;
    background-image: linear-gradient(to right, #42a5f5 0%, #42a5f5 100%);
  }
  
  
  .price-section {
    margin-bottom: 0px;
  }
  
  #followerCount {
    margin-bottom: 30px;
    padding: 20px 25px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid #dadfed;
  }
  
  .calc-btn {
    margin-bottom: 30px;
    padding: 20px 25px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    background-color: black;
    border-radius: 10px;
    border: 1px solid #dadfed;
    cursor: pointer;
  }

.calc {
    text-align: left;
    font-weight: 500;
}

#calc-title {
    margin-bottom: 0px;
}

#calc-explainer {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    color: #6E7984;
}

#calc-explainer a {
    text-decoration: underline;
    font-weight: 500;
    color: #000000;
}

#upbirdstandard {
    font-weight: 500;
}

#fairvalue {
    text-align: center;
    background-color: rgb(251, 238, 243);
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    color: #dd126d;
    font-weight: 500;
    margin-bottom: 30px;
}

#fairvalue a {
    text-decoration: underline;
    color: #000000;
}

.price-content {
    display: flex;
}

.price-section {
    padding: 15px;
    text-align: middle;
    border-radius: 10px;
    border: 1px solid #dadfed;
    width: 150px;
    margin-right: 15px;
}

.infoicon {
    margin: 0px;
    margin-left: 3px;
    margin-bottom: -2px;
    padding: 0px;
    color: #6E7984;
}

.tippy-tooltip {
    background-color: rgb(255, 255, 255);
    border: 1px solid #dadfed;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    font-size: 14px;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
  }

  #price-title {
    font-weight: 500;
  }