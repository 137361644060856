.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-bottom: 1px solid #dadfed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;  
}

.navbar .rightSide {
  margin-left: auto; /* Changed from margin-right to margin-left */
  display: flex;
  justify-content: flex-end;
}

.leftSide {
  margin-left: 30px;
  font-size: 12px;
  font-weight: 600;
}

#upbird {
  color: black;
}

.navbar a {
  color: #6e7984;
  text-decoration: none;
  font-weight: 400;
  margin: 20px;
}

.navbar a.selected {
  color: black;
}

#home {
  padding: 4px;
  font-weight: 400;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #eff1f5;
}

#home.selected {
  padding: 4px;
  font-weight: 400;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dadfed;
}

.dropdown {
  background-color: rgb(255, 255, 255);
  border: 1px solid #dadfed;
  padding: 20px;
  margin-bottom: 60px;
  position: absolute;
  top: 13%;
  left: 0;
  transform: translate(0, 0) rotate(0);
  width: 100%;
}

@media screen and (min-width: 601px) {
  .dropdown {
    display: none;
  }
}

.open {
  display: block;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}


@media screen and (max-width: 600px) {
  .hamburger {
    margin-right: 50px;
    display: block;
  }
}