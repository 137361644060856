.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
}

h1, h3, h2, h4 {
  font-weight: 600;
}

body {
  padding: 0;
  margin: 0;
}

.tippy-tooltip2 {
  background-color: white;
  border: 1px solid #dadfed;
  border-radius: 10px;
  color: black;
  font-size: 14px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}