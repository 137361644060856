.user-page {
  margin: 3%;
  font-family: 'Poppins', sans-serif;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  /* ...other styles... */
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .user-page {
    margin: 5px;
    /* ...other styles... */
  }
}

#creatorstitle {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.follower-range.none-999 {
    color: #6E7984;
  }
  
  .follower-range.onek-fourk {
    color: #4685FF;
  }
  
  .follower-range.onek-onehundredk {
    color: green;
  }

  .creators-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    margin: 0 auto;
  }
  
  .creator-card {
    max-width: 300px;
    margin-bottom: -30px;
    padding: 40px;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .creator-card img {
    width: 300px;
    height: auto;
    border-radius: 20px;
    border: 1px solid #DADFED;
    margin-bottom: -10px;
    overflow: hidden;
  }

  .creator-card__image {
    width: 300px;
    height: auto;
    transition: transform 0.3s ease;
  }

  .creator-card__name a {
    text-decoration: none;
  }

  .creator-card__name {
    font-size: 32px;
    text-decoration: none;
    Color: black;
    margin-top: 5px;
    margin-bottom: 2px;
  }

.creator-card__handle {
    float: right;
    margin-bottom: 2px;
}

.creator-card__follower-range {
    float: left;
}
  
  .offers-container p {
    color: #6E7984;
  }

  .offers-container {
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    background-color: #ffffff;
    border: 1px solid #DADFED;
    border-radius: 20px;
    padding: 20px;
    display: grid;
    grid-gap: 20px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 600px) {
    .offers-container {
      width: 90%;
      background-color: #ffffff;
      border: 1px solid #DADFED;
      border-radius: 20px;
      padding: 20px;
      margin: auto;
      display: grid;
      grid-gap: 20px;
      margin-bottom: 30px;
    }
  }

  .offer-rectangle {
    width: 95%;
    background-color: #ffffff;
    border:1px solid #DADFED;
    border-radius: 20px;
    padding: 20px;
    margin: auto;
    margin-bottom: 0px;
    align-items: center;
    margin-bottom: 0px;
    justify-content: unset;
    position: relative;
  }

  @media only screen and (max-width: 600px) {
    .offer-rectangle {
      width: 80%;
      background-color: #ffffff;
      border:1px solid #DADFED;
      border-radius: 20px;
      padding: 20px;
      margin: auto;
      margin-bottom: 0px;
      align-items: center;
      margin-bottom: 0px;
      justify-content: unset;
      position: relative;
    }
  }

  .offer-rectangle2 {
    width: 95%;
    background-color: #ffffff;
    border: 1px solid #DADFED;
    border-radius: 20px;
    padding-top: 2px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 2px;
    margin: auto;
    margin-bottom: 0px;
    justify-content: unset;
    position: relative;
  }

  @media only screen and (max-width: 600px) {
    .offer-rectangle2 {
      width: 80%;
      background-color: #ffffff;
      border: 1px solid #DADFED;
      border-radius: 20px;
      padding-top: 2px;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 2px;
      margin: auto;
      margin-bottom: 0px;
      justify-content: unset;
      position: relative;
    }
  }

  .price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: right;
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .offer-rectangle2:hover {
    cursor: pointer;
    background-color: #FCFDFF;
  }

  .offer-rectangle:hover {
    cursor: pointer;
    background-color: #FCFDFF;
  }

  #offer-title {
    margin-bottom: 0px;
  }
  
  #offer-p {
    font-size: 16px;
    margin-top: 0;
  }

  @media only screen and (max-width: 600px) {
    #offer-p {
      width: 70%;
      font-size: 16px;
      margin-top: 0;
    }
  }

  .price {
    font-size: 24px;

  }

  .offers-text {
    margin: 5px;
    margin-bottom: -8px;
    font-size: 18px;
  }
  
  .offer-rectangle h3 {
    margin: 0;
  }

  .offer-rectangle p {
    margin: 10px 0 0;
  }

  .user-page img {
    width: 80px;
    height: auto;
    border: 1px solid #DADFED;
    border-radius: 20px;
  }

  .user-page {
    padding: 20px;
  }

  .user-info p {
    line-height: 10px;
    color: #6E7984;
  }

  .user-info  {
    line-height: 10px;
    margin-bottom: 50px;
    margin-right: 0px;
    margin-left: 0px;
    justify-content: center;
    text-align: center;
  }

  .modal {
    border-radius: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 150px;
    padding-bottom: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow-y: auto;
  }

  .modal-open {
    overflow: hidden;
  }

  .close-button {
    position: absolute;
    top: 0px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: rgb(0, 0, 0);
  }
  
  .close-button svg {
    width: 100%;
    height: 100%;
  }

  .close-button:before,
.close-button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 2px;
  background-color: #000;
}

.close-button:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-button:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
  
  .modal-conten {
    border-radius: 0px;
    background-color: white;
    width: 100%; 
    padding: 20%;
  }
  
  @media only screen and (max-width: 600px) {
    .modal-conten {
      border-radius: 0px;
      background-color: white;
      width: 100%; 
      padding: 5%;
    }
    }

  .demooffer {
    background-color: #ffffff;
    border: 1px solid #DADFED;
    border-radius: 20px;
    padding: 20px;
    margin: auto;
    align-items: center; /* Add this property */
  justify-content: center; /* Add this property */
  }

  .demooffer h3 {
    margin: 0;
  }

  .demooffer p {
    margin: 10px 0 0;
  }

  #infosec {
    background-color: #E4ECFF;
    border: 1px solid #4685FF;
    color: #4685FF;
    border-radius: 20px;
    padding: 20px;
  }

  #line {
    background-color: #DADFED;
    width: 100%;
    height: 1px;
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .paydetails-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px; /* Adjust the gap between columns as desired */
    line-height: 5px;
    margin-top: 20px;
  }
  
  .paydetails-column.left-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    color: #6E7984;
    font-weight: 600;
    margin-right: auto; /* Add this property */
  }
  
  .paydetails-column.right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Change this to align items to the right */
    text-align: right;
    color: #000000;
    font-weight: 600;
    margin-left: auto; /* Add this property */
  }

  .messagebtn {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #DADFED;
    background-color: #47ACDF;
    color: white;
    padding: 15px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  #modal-title {
    margin-bottom: -10px;
  }

  #modal-subtitle {
    color: #6E7984;
    margin-bottom: 20px;
  }


  .content-container {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Adjust the column proportions as needed */
    grid-gap: 20px;
  }

  @media only screen and (max-width: 600px) {
    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }}



  .creator-card__handle {
    color: #6E7984;
  }

  .none-999 {
    color: #6E7984;
    font-weight: 500;
  }
  
  .onek-threek {
    color: #33AD3F;
    font-weight: 500;
  }
  
  .threek-sixk {
    color: #3389AD;
    font-weight: 500;
  }
  
  .sixk-tenk {
    color: #5333AD;
    font-weight: 500;
  }
  
  .tenk-fifteenk {
    color: #C8287E;
    font-weight: 500;
  }
  
  .fifteenk-twentyk {
    color: #ea3a0e;
    font-weight: 500;
  }
  
  .twentyk-twentyfivek {
    color: #C29622;
    font-weight: 500;
  }
  
  .twentyfivek-thirtyk {
    color: #AD5F33;
    font-weight: 500;
  }
  
  .thirtyk-plus {
    color: black;
    font-weight: 500;
  }

  .copy-section {
    border-radius: 20px;
    color: #6E7984;
    padding-left: 20px;
    background-color: #f8f9fb;
    display: flex; /* Add this property */
    align-items: center; /* Add this property */
  }

  .copy-button {
    padding: none;
    cursor: pointer;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #DADFED;
    background-color: white;
    color: #000000;
    font-weight: 500;
    margin: 17px;
  }

  .copy-text {
    flex: 1; /* Add this property */
    padding: 5px;
    margin: 0;
    font-size: 14px;
  }

  .copy-popup {
    position: absolute;
    margin-bottom: 85px;
    margin-right: 15px;
    top: 1;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 16px;
    border-radius: 10px;
    font-size: 14px;
  }

  .copy-popup.show {
  display: block;
}

.infoicon {
  margin-top: 15px;
  margin-right: 10px;
}

#user-page-handle {
  margin-bottom: 15px;
}

.creator-card__tags {
  overflow: hidden;
}

.tag-money {
  padding: none;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #eff1f5;
  color: #6E7984;
  margin-right: 5px;
}

.tag-entrepreneurship {
  padding: none;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #eff1f5;
  color: #6E7984;
  margin-right: 5px;
  /* Add other specific styles for the "Money" tag */
}

.tag-startups {
  padding: none;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #eff1f5;
  color: #6E7984;
  margin-right: 5px;
  /* Add other specific styles for the "Money" tag */
}

.tag-web3 {
  padding: none;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #eff1f5;
  color: #6E7984;
  margin-right: 5px;
  /* Add other specific styles for the "Money" tag */
}

.tag-creative {
  padding: none;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #eff1f5;
  color: #6E7984;
  margin-right: 5px;
  /* Add other specific styles for the "Money" tag */
}

.tag-writing {
  padding: none;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #eff1f5;
  color: #6E7984;
  margin-right: 5px;
  /* Add other specific styles for the "Money" tag */
}

.tag-tech {
  padding: none;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #eff1f5;
  color: #6E7984;
  margin-right: 5px;
  /* Add other specific styles for the "Money" tag */
}

.tag-gem {
  padding: none;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #d6f0fc;
  color: #6E7984;
  margin-right: 5px;
  /* Add other specific styles for the "Money" tag */
}

.creator-card__tags {
  margin-bottom: 10px;
  Width: 300px;
}

.filter-container {
display: flex;
align-items: center;
justify-content: flex-end;
margin-left: auto;
}

#niche-filter {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 150px;
  padding: 10;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;  
  border: 1px solid #DADFED;
  background-color: white;
  color: #000000;
  position: relative;
}

.CreatorsPage-top {
  margin: 70px;
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
 }

#fee-strike {
  text-decoration: line-through;
  color: #6E7984;
}

.tippy-tooltip {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  padding: 8px;
  width: 700px;
  height: auto;
  margin-bottom: 5px;
  position: relative;
  border-radius: 10px;
  text-align: center;
  background-color: rgb(29, 29, 34);
  border: 1px solid rgb(69, 69, 84);
  color: rgb(255, 255, 255);
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  width: 200px;
  padding-right: 25px; /* Adjust as needed */
}

.custom-select-arrow {
  position: absolute;
  top: 60%;
  right: 15px; /* Adjust as needed */
  transform: translateY(-50%);
  pointer-events: none;
}

#TwitterTimelineEmbed {
  max-width: 1300px;
  margin: auto;
  margin-top: 30px;
  justify-content: middle;
  text-align: center;
}

.creator-card__buttons {
  position: relative;
  margin-top: -25px;


  display: none; /* Initially hide the buttons */
}

.creator-card:hover .creator-card__buttons {
  display: block; /* Show the buttons when hovering over the card */
}

.creator-card__button {
  padding: 8px 16px;
  font-weight: 500;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  border: none;
  border-radius: 0 10px 0 0;
  cursor: pointer;
  margin: -4px;
  /* Add styles for the buttons */
}

#creators-apply {
  color: #0A77DC;
  border-radius: 10px;
  margin-top: 40px;
  text-align: center;
  margin-left: 20px;
  padding: 5px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  background-color:rgb(239, 245, 253)
}

#creators-apply:hover {
  color: #0A77DC;
  border-radius: 10px;
  text-align: center;
  margin-left: 20px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color:rgb(231, 240, 251);
  transition: .2s;
  cursor: pointer;
}

.creator-card__image-container {
  position: relative;
  display: inline-block;
}

.thumbs-up-icon {
  color: white;
  background-color: rgba(34, 181, 255, 0.5);;
  border-radius: 10px;
  padding: 5px;
  padding-right: 8px;
  padding-left: 8px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
}

#thumbicon {
  margin: 0px;

}

.tippy-tooltip2 {
  background-color: white;
  border: 1px solid #dadfed;
  border-radius: 10px;
  color: black;
  font-size: 14px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}

#sub-step {
  color: #6E7984;
  font-weight: 500;
}

#step {
  color: #000000;
  font-weight: 600;
}

.pay-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-right: 0px;
  margin-left: 0px;
}

.pay-box {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 20px;
  margin-right: 15px;
  border: 1px solid #dadfed;
  padding: 40px;
  text-decoration: none;
  color: #0000;
  transition: background-color 0.3s ease;
}

.pay-box2 {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #dadfed;
  padding: 40px;
  text-decoration: none;
  color: #000;
  transition: background-color 0.3s ease;
}

.pay-box:hover {
  background-color: #FCFDFF;
}

.pay-box2:hover {
  background-color: #FCFDFF;
}

.pay-box-content {
  text-align: center;
  color: #6E7984;
  font-weight: 500;
}

.pay-area {
  margin-top: 15px;
  margin-right: 0px;
  margin-left: 0px;
}

#pay-icon {
  padding: 10px;
  border: 1px solid #dadfed;
  border-radius: 10px;
  width: 40px;
  height: auto;
}

#but {
  background-color: black;
}

#back {
  color: #6E7984;
  font-weight: 500;
  cursor: pointer;
}

#back svg {
  width: 30px;
  height: auto;
  margin-bottom: -9px;
  margin-left: -6px;
}

.profile-tags {
  margin-top: 15px;
}

.howcard {
  padding: 25px;
  text-align: center;
  border: 1px solid #DADFED;
  border-radius: 20px;
  text-decoration: none;
  background-color: white;
}
x
.howcard-title {
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
}

.howcard-sub {
  margin: 0px;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #6E7984;
  text-decoration: none;
}

.howcard-container {
  margin-top: 30px;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  text-decoration: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  text-decoration: none;
}

.howcard-container a {
  text-decoration: none;
}

#howicon {
  width: 100px;
  height: auto;
  border: 0px;
}

#howicon2 {
  width: 80px;
  margin-top: 18px;
  height: auto;
  border: 0px;
}

#howtitle {
  margin-top: 30px;
  margin-bottom: -10px;
}

.twitter-timeline {
  margin-right: auto;
  margin-left: auto;
  justify-content: middle;
  text-align: center;
  max-width: 1300px;
}